import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import '../styles/doc.scss'
import { Helmet } from "react-helmet"


const PrivacyPolicy = () => {
  return (
    <>
    <Helmet htmlAttributes={{ lang: "en" }} title={'Terms and Conditions - Cubo'}>
      <meta name="description" content={"Learn about the parties' responsibilities, rights, data protection, use of information, and cancellation of the service."} />
    </Helmet>
    <Layout>
      <div
        className="termsofservice-section"
        id='termsofservice-section'
        style={{ display: "flex", justifyContent: "center",  padding: '0 20px' }}
      >
        <div style={{ maxWidth: "1024px", fontSize: '16px', color: '#000' }}>
          <h1 className='h3' style={{ textAlign: 'center',fontSize: '24px', fontWeight:'500',margin:'0 auto 20px'  }}>Terms of Service</h1>
          <h3 className='h5' style={{ textDecoration: 'none' }}>Effective Date:  [November 9], 2022</h3>
          <div className="content">
          This Terms of Service (“this Terms”) is entered into by and between you and Cubo World Inc., a company registered under the law of Delaware with its registered office at 108 West 13th Street, Wilmington, Delaware 19801, USA (hereinafter referred to as “Cubo”, “Us” , “We” or “Our”), regarding your use of Cubo ( “our application”), websites and related services (the “Service”). Your use of the Service is also governed by our <Link className="link" to={'/privacypolicy'}>Privacy Policy</Link> and other relevant policies (collectively,“these Terms”). Please thoroughly and carefully read this Terms and our Privacy Policy before use or access the Service, especially the content about the restrictions on the users’ rights, dispute resolution, exemption from or limitations on our liability, and those in bold font and/or BLOCK CAPITAL. 
          </div>
          <div className="bold content" >
          PLEASE NOTE, IF YOU CONTINUE USING OR ACCESSING THE SERVICE, YOU WILL BE DEEMED THAT YOU HAVE FULLY UNDERSTOOD AND ACCEPTED, AND IS WILLING TO COMPLY WITH ALL THE FOLLOWING CLAUSES, AND THIS TERMS SHALL BECOME EFFECTIVE BETWEEN YOU AND US. IF YOU DISAGREE WITH THIS TERMS IN WHOLE OR PART, YOU SHALL IMMEDIATELY STOP USING THE SERVICE.
          </div>

          <div className="content">
          We reserve the right to modify or change this Terms, our <Link className="link" to={'/privacypolicy'}>Privacy Policy</Link> and other relevant policies from time to time by posting the revised terms on our website, application and other platforms. If you disagree with the revised terms in whole or in part, you have the right to stop the Service agreed herein; if you continue using the Service, you will be deemed have fully read, understood and accepted the revised Terms.
          </div>

          <div className="title  FW_500">
            <span>1</span>
            User Account</div>
          <div className="title">
            <span>1.1</span>
            You hereby acknowledge that when you complete the registration procedures or otherwise actually use the Service in such a manner as permitted by us, you are a natural person, legal person or other organization that has full capacity for civil rights and for civil conducts conducted by you. If you are not such an eligible user, please do not use the Service; otherwise, you and your guardian shall bear all consequences arising therefrom and we have the right to close (permanently freeze) your account and claim loses and damages to us against you and/or your guardian. If you registrate or actually use the Services in such manners as permitted by us on behalf of a company, organization or other legal entity, you hereby represent and warrant that you have been duly authorized and have the right to make such company, organization or legal entity bound by the terms of this Agreement.
          </div>

          <div className="title">
            <span>1.2</span>
            To register an account provide by our application, you shall submit true, accurate, complete and valid information. You understand and agree that the account name registered and profile and other account information set by you shall not include any illegal or offensive information, and that you shall not pretend to be others, or register for others without his/her consent, or use user names that may infringe upon interests of others (including but not limited to potential infringement upon trademark right or right of reputation); otherwise, we are entitled to reject your registration or terminate the provision of Services to you and cancel your account without further notice, with any and all losses incurred therefrom solely borne by you.
          </div>

          <div className="title">
            <span>1.3</span>
            After your successful registration, you shall properly keep your terminal, account name and password and/or identity elements and be liable for all activities (including but not limited to clicking “Accept” for or submitting various Rules and agreements online, purchasing Services, sharing information or image, initiating conference call) carried out by using such account and password and/or identity elements. You undertake to notify us immediately if your password or account is used without authorization or in case of any other security incident, and except for otherwise stipulated by laws, you agree and acknowledge that we will not be liable for any direct or indirect loss or damages caused under such circumstance. Unless under applicable laws or judicial rulings and with our prior consent, your account name and password may not be assigned, bestowed or inherited in any manner (except for property rights and interests in connection with the account).
          </div>

          <div className="title">
            <span>1.4</span>
            If you deregistration your account and stop using the Service, we shall be entitled to dispose the contents/data and information relevant with your account, including but not limited to deletion of the same. If the account is an administrator account, we shall be entitled to cancel and delete such administrator account and all information and relevant rights and interests of the members in its contact list formed on the basis of this enterprise without further notice, including but not limited to terminating its service relationship with the company, organization and other legal entity, deleting the contact list of the company, organization and other legal entity, canceling the rights and interests of the members in the contact list in connection with the company, organization and other legal entity, deleting all information in the administrator account, and deleting all information of members in the contact list of the company, organization and other legal entity formed on the basis of such enterprise. Meanwhile, You and/or your company/organization is obliged to cancel the above mentioned company/organization’s data and/or information.
          </div>

          <div className="title FW_500">
            <span>2</span>
            Features of Our Application</div>
          <div className="title">
            <span>2.1</span>
            Basic Features. After you have been successfully startup our service, you can use it to initiate conference call, schedule meetings, attend meetings and chat with your colleagues. To fulfill the same function, we need to access your microphone and camera on your device, and you may adjust the permission status through “Settings” of our application or websites. Of course, you have the right to refuse before answering a colleague's invitation to communicate. 
          </div>
          <div className="title">
            <span>2.2</span>
            Staff Monitor. Our application provides staff monitor function to company/organization, which allows company/organization to track their staff users’ working hours through keyboard active status.
          </div>
          <div className="title FW_500">
            <span>3</span>
            Use of Service
          </div>
          <div className="title">
            <span>3.1</span>
            We grant you a worldwide, non-exclusive and non-transferable right to use the Service in compliance with this Terms. You <span className="bold">SHALL NOT</span> use, copy, republish, download, modify, distribute, license, sublicense, decompile, disassemble, create a derivative work based on, or reverse engineer the application or other products, services, or processes accessible through our website or application except as otherwise expressly stated in this Terms.
          </div>
          <div className="title">
            <span>3.2</span>
            We reserve all rights, including but not limited to all intellectual property rights and other proprietary rights to and relating to our application. You <span className="bold">SHALL NOT</span> copy, redistribute, publish, create any derivative work from, or otherwise exploit any content of our application without our prior written consent. 
          </div>
          <div className="title">
            <span>3.3</span>
            We’re constantly developing new technologies and features to improve the Service. If we make material changes that negatively impact your use of the Service or if we stop offering a service, we’ll provide you with reasonable advance notice, but we <span className="bold">WILL NOT</span> compensate you for any losses arising out of such changes or termination.
          </div>
          <div className="title">
            <span>3.4</span>
            If you think someone is infringing your intellectual property rights, you can send us notice of the infringement and we’ll take appropriate action. For example, we may suspend or close services of repeat copyright infringers.
          </div>
          <div className="title">
            <span>3.5</span>
            Your use of the Service is governed by this Terms and our Privacy Policy and other relevant policies. PLEASE NOTE that violation of any provision of the same, may result in suspension and/or termination of the license under this Terms.
          </div>
          <div className="title FW_500">
            <span>4</span>
            Our Ownership and Intellectual Property Rights
          </div>
          <div className="title">
            <span>4.1</span>
            All rights, titles and interests in and to the Service (including without limitation any logos, application names, layouts, audio-visual effects and other materials) are owned by or legally licensed to us. We and the corresponding right holders reserve all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with the Service. You understand and agree that you are only permitted to use the same content to the extent necessary to be user of our application.
          </div>
          <div className="title">
            <span>4.2</span>
            You hereby acknowledge and agree that any contents associate with your use of the Service, including but not limited to any comments and/or feedback you provide to us, shall be owned by us. We reserves the right to use such contents for any purpose, including but not limited to develop our application and other products, and we have no obligation to compensate or otherwise pay you for such uses. 
          </div>
          <div className="title">
            <span>4.3</span>
            Provided that you are deemed to have retained any right to any contents associate with your use of the Service, you hereby grant us the sole, exclusive, irrevocable, sublicensable, transferable, worldwide, royalty-free license to copy, reproduce, modify, adapt, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any form, your contents as well as all modified and derivative works thereof in connection with our provision of the Service, including without limitation developing and marketing of our application and other products.
          </div>
          <div className="title">
            <span>4.4</span>
            You hereby grant us the right to authorize others to exercise any of the rights granted to us under this Terms. 
          </div>
          <div className="title FW_500">
            <span>5</span>
            Terms and Fees
          </div>
          <div className="title">
            <span>5.1</span>
            This Terms shall begin upon your registration for the Service and shall continue until your use of the Service is terminated by you or by us.
          </div>
          <div className="title">
            <span>5.2</span>
            Provided prior notice of pricing is given to you, we reserve the right to charge you for use of the Service on a periodic (e.g., weekly or monthly) basis or otherwise. You are responsible for all fees due to us and any compensation arising from any damage caused by your use of the Service. 
          </div>
          <div className="title">
            <span>5.3</span>
            In case of refund, since any purchase related to our application will be addressed by third-party payment providers, as well as any refund arising out the purchase. Therefore, refund policies of third-party payment providers will be applied under any refund circumstances. <span className="bold">PLEASE NOTE</span> that any applicable taxes and service charges (if any) incurred by your purchase and refund shall be borne by yourself. For more refund details, please contact us through the “Contact Details” we set out in this Terms.
          </div>
          <div className="title FW_500">
            <span>6</span>
            Updates to the Service
          </div>
          <div className="title">
          We reserve the right to update the Service from time to time, and to continue using the Service, you may require to accept such updates. If you fail to update, we may unable to continue providing the Service to you.
          </div>
          <div className="title FW_500">
            <span>7</span>
            User Restrictions 
          </div>
          <div className="title">
            <span>7.1</span>
            The following restrictions apply to your use of the Service. You are not allowed to:

            <ul>
              <li>allow other persons to use your account, and you agree that you are the sole authorized user of your account; </li>
              <li>impersonate any person or entity;</li>
              <li>copy, modify, create derivatives of, decompile, or reverse engineer the Service or take any action to interfere with our application’s intellectual property rights and any other legal interests;</li>
              <li>upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our application;</li>
              <li>use the Service for any illegal purpose, such as discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation by posting comments;</li>
              <li>use the Service in any way that may infringe any third party’s intellectual property rights, including without limitation copyrights, patents, trademarks and trade secrets;</li>
              <li>use the Service in any way that may infringe any third party’s privacy rights. For example, recording and disclosing any talking recording without permission from corresponding subject;</li>
              <li>post feedback on Google Play, App Store or any social media platform in a manner which is false or misleading;</li>
              <li>use the Service in any way that may violate applicable laws, regulations or policies of relevant jurisdictions;</li>
              <li>Restrictions under this term shall survive upon termination of this Terms.</li>
            </ul>
          </div>
          <div className="title FW_500">
            <span>7.2</span>
            We shall have the right to interrupt or terminate the provision of the services (including both paid and free services) under this Terms of Users at any time without any liability to the User or any third party in the event of any of the following circumstances, and the resulting losses shall be borne by the User independently.

            <ul>
              <li>The user violates laws and regulations or the rules of use set forth in this Terms；</li>
              <li>The user's failure to pay the relevant service fees for the fee-based services used by the user in accordance with the regulations when using the fee-based services.</li>
              <li>The User infringes the legitimate rights and interests of individuals, enterprises, institutions or social groups；</li>
              <li>The user damages the image of regulatory authorities, state agencies and the government.</li>
              <li>The user damages in any way the legitimate rights and interests of Cubo and its affiliates.</li>
            </ul>
          </div>
          <div className="title">
            <span>7.3</span>
            You can use a nickname to set up a short link, and this short link can be shared in your social platforms to make it easier for your friends to access your Cubo work platform. However, you should note that you only have the right to use the short link but not the right to own it.
            <div className="bold">
            If your short link or nickname involves security issues, or violates the rights of others, or uses sensitive or inappropriate words, we have the right to suspend the use of the short link by the user and ask you to modify it until it meets the requirements of the Terms. During this period, you may not be able to access or allow others to access your Cubo work platform normally.
            </div>
          </div>
          <div className="title">
            <span>7.4</span>
            If the User does not actually use the Service within any period of [ 180 ] days after the User has applied for the Service, we shall have the right to choose to proceed in any of the following ways to the extent permitted by laws and regulations.

            <ul>
              <li>Reclaiming the User's nickname.</li>
              <li>Recalling the User's account.</li>
              <li>Disable the User's short link.</li>
              <li>Stop providing services to the user.</li>
            </ul>

            <span className="bold">PLEASE NOTE</span> that we may reject, refuse to post or delete your comments or any other content if we believe that your content violates this term at our sole discretion.
          </div>
          <div className="title FW_500">
            <span>8.</span>
            Miscellaneous
          </div>
          <div className="title">
            <span>8.1</span>
            The Service we provide under this Terms may contain third-party websites or website links, and you shall decide whether to access these links. We are not responsible for the accuracy, completeness, adequacy and reliability of any information, data, opinions, pictures, statements or suggestions made available on these websites. If you decide to visit any third-party website linked to our application or website, you shall be solely responsible for the possible results and risks therefrom. 
          </div>
          <div className="title">
            <span>8.2</span>
            If any provision of this Terms is held to be illegal, invalid or unenforceable under applicable law, such provision thereof shall to that extent be deemed not to form part of this Terms, and shall not affect the legality, validity and enforceability of other provisions.
          </div>
          <div className="title">
            <span>8.3</span>
            You understand and agree that we may assign or transfer this Terms in whole or in part to either a subsidiary or affiliate of us, or a successor by merger or acquisition.
          </div>
          <div className="title FW_500">
            <span>9.</span>
            Governing Law and Jurisdiction
          </div>

          <div className="title">
            <span>9.1</span>
            Except to the extent that: (i) any applicable additional terms incorporated into these Terms provide differently, or (ii) the applicable laws and regulations of your jurisdiction mandate otherwise (for instance, you may have statutory rights in your jurisdiction in relation to bringing or defending claims in a local court (including small claims court (or similar court)), these Terms and any dispute or claim arising out of or in connection with these Terms will be governed by Laws of Delaware.
          </div>

          <div className="title">
            <span>9.2</span>
            Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in connection with these Terms, including their existence, validity, interpretation, performance, breach or termination, will be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”), which rules are deemed to be incorporated by reference in this Section.  The seat of such arbitration shall be Singapore and all proceedings shall be conducted in the English language.  Any arbitration commenced pursuant to this Section shall be conducted in accordance with the Expedited Procedure set out in Rule 5 of the SIAC Rules.  The Tribunal shall consist of one arbitrator to be appointed by the President of the Court of Arbitration of the Singapore International Arbitration Centre.  Deposits and fees to cover the costs of arbitration shall be shared equally by the disputing parties.  The award rendered by the arbitrator shall, in addition to dealing with the merits of the case, fix the costs of the arbitration and decide which of the parties shall bear such costs or in what proportions such costs shall be borne by the parties hereto.  The award rendered by the arbitrator or arbitrators shall be final, conclusive and binding on all parties to this Agreement and shall be subject to execution and enforcement in any court of competent jurisdiction.
          </div>
          <div className="title FW_500">
            <span>10</span>
            Contact Details
          </div>
          <div className="title">
          If you have any questions about this Terms or the Service we provide hereunder, please contact us in the following ways:
          </div>
          <div className="title">Full name of legal entity: Cubo World Inc.</div>
          <div className="title">Email address: contact@cubo.to</div>
          <div className="title">Address: 108 West 13th Street, Wilmington, Delaware 19801, USA</div>

          <div className="content FW_500">DISCLAIMER.</div>
          <div className="content">THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, WE MAKE NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. WE DO NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</div>

          <div className="content FW_500">LIMITATION OF LIABILITY.</div>
          <div className="content">WE SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WE SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WE SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE SERVICE FEE WE HAVE CHARGED FROM YOU FOR THE CORRESPONSING SERVICE.</div>
          <div className="content">THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</div>

          <div className="content FW_500">INDEMNITY</div>
          <div className="content">YOU UNDERTAKE TO INDEMNIFY US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, CHAUFFEURS ON DEMAND AGAINST ALL CLAIMS, ACTIONS, CHARGES, COSTS, EXPENSES, DEMANDS, DAMAGES, LIABILITIES, PROCEEDINGS OR JUDGMENTS WHICH ARISING OUT OF OR IN CONNECTION WITH YOUR BREACH OR VIOLATION OF THIS TERMS, OR YOUR INFRINGEMENT OF ANY THIRD PARTY’S RIGHTS.</div>
        </div>
      </div>
    </Layout>
    </>
  )
}

export default PrivacyPolicy
